<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card tile flat v-if="loaderdata === false">
                    <v-card max-width="100%" flat tile class="bordo-r">
                        <v-card-text class="d-flex flex-row">
                            <h2 class="">
                                <strong>{{ jobitem.title }}</strong>
                            </h2>
                        </v-card-text>
                        <v-card-text>
                            <v-list-item two-line class="pa-0">
                                <v-list-item-avatar size="50">
                                    <v-img src="@/assets/images/avatars/profil.svg"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ jobitem.company ? jobitem.company.name : '' }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-icon small>{{ icons.mdiMapMarkerOutline }}</v-icon>
                                        {{ jobitem.location }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                </v-list-item-action>
                            </v-list-item>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text class="d-flex flex-row">
                            <span class="mr-4">
                                <v-icon>{{ icons.mdiBriefcase }}</v-icon>
                                {{ jobitem.periodicity }}
                            </span>
                            <v-spacer></v-spacer>
                            <span class="error--text">
                                <v-icon color="error" class="mr-1">{{ icons.mdiAlertOutline }}</v-icon>
                                Dateline: {{ jobitem.dateline }}
                            </span>
                        </v-card-text>
                        <v-divider></v-divider>
                        <!-- <v-card-text outlined class="py-4" v-html="jobitem.description"></v-card-text>
                        <detail-about-company :company="jobitem.company"></detail-about-company> -->
                    </v-card>
                    <v-list one-line>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Write to the company about this offer</v-list-item-title>
                                <v-list-item-subtitle>
                                    <vue-editor v-model="response" outlined dense class="text-left mb-3" label="Body"
                                        placeholder="Description of your acceptation">
                                    </vue-editor>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list three-line subheader>
                        <v-subheader>Attach others documents</v-subheader>
                        <v-list-item>
                            <v-list-item-action>

                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>#</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" class="px-4" outlined @click="dialog = false"> Cancel
                        </v-btn>
                        <v-btn class="secondary px-4" @click="sentJobResponse">
                            Submit
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <v-card v-else>
                    <v-skeleton-loader type="card-avatar, article, article, actions"></v-skeleton-loader>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

</template>
<script>
import { VueEditor } from "vue2-editor";
import { mdiClose, mdiAlertOutline } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'

export default {
    data() {
        return {
            jobitem: {},
            dialog: false,
            response: '',
            loadingChecked: true,
            loaderdata: true,
        }
    },
    components: {
        VueEditor
    },
    methods: {
        sentJobResponse() {
            var bodyFormData = {
                "jobresponse": {
                    "enterprise.id": this.jobitem.company.id,
                    "job.id": this.jobitem.id,
                    "user.id": this.$store.getters.getUser.id,
                    "response": this.response,
                }
            }
            Drequest.api(`jobresponse.create`)
                .data(bodyFormData)
                .raw((response) => {
                    if (response.success === true) {
                        this.$fire({
                            type: "success",
                            text: response.detail,
                            timer: 3000
                        })
                        this.loadingDatas = false
                        this.$router.push({ name: 'vacancies.list' })
                    } else {
                        this.$fire({
                            type: "error",
                            text: "Error occured, please retry later..!",
                            timer: 3000
                        })
                        this.loadingDatas = false
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.loadingDatas = false
                })
            this.dialog = false
        }
    },
    beforeMount() {
        this.loaderdata = true
        Drequest.api(`lazyloading.job?dfilters=on&id:eq=${this.$route.params.offer_id}`)
            .get((response) => {
                if (response.success === true) {
                    this.jobitem = response.listEntity[0]
                    this.loaderdata = false
                }
                else {
                    this.loaderdata = false
                }
            })
            .catch((err) => {
                this.loaderdata = false
            });
    },
    setup(props) {
        const icons = { mdiClose, mdiAlertOutline }
        return {
            icons
        }
    }
}
</script>